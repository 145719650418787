import { useSocketStoreHook } from "@/store/modules/socket"

function ListenerVisibleChange(){    

    document.addEventListener('visibilitychange', function () {
        // 用户离开了当前页面
        if (document.visibilityState === 'hidden') {
            console.log("页面不可见，隐藏了");
        }
        
        // 用户打开或回到页面
        if (document.visibilityState === 'visible') {
            const currentPath = window.location.pathname;
            const socketStore = useSocketStoreHook();

            // if(socketStore.Keep_Live_Timer === null && (currentPath === '/' || currentPath === '/room')){
            if(socketStore.Keep_Live_Timer === null && (currentPath === '/room')){
                location.reload();
            }
            console.log("页面可见", socketStore.Keep_Live_Timer);
        }
    });
}

function WeixinJSBridgeRun(){

    // if (typeof window?.WeixinJSBridge == "undefined"){
    //     if( document.addEventListener ){
    //         document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
    //     }else if (document.attachEvent){
    //         document.attachEvent('WeixinJSBridgeReady', onBridgeReady); 
    //         document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
    //     }
    // }else{
    //     onBridgeReady();
    // }

    // function onBridgeReady(){
    //     window.WeixinJSBridge.call('hideToolbar');        // 隐藏底部状态栏
    //     window.WeixinJSBridge.call('hideOptionMenu');     // 隐藏右上角的三个点的选项
    //     window.WeixinJSBridge.call('showToolbar');        // 显示底部状态栏
    //     window.WeixinJSBridge.call('showOptionMenu');     // 显示右上角的三个点的选项
    // }
}

function GetClientWidth(){
    const ClientWidth = document.documentElement.clientWidth;
    return ClientWidth > 500 ? 500 : ClientWidth;
}

function SetFontSize(width){
    document.documentElement.style.fontSize = width / 3.75 + 'px';
    return width / 3.75;
}

export function setAppGlobalProperties(app){
    const GlobalProperties = app.config.globalProperties;

    ListenerVisibleChange();
    WeixinJSBridgeRun();

    const HtmlFontSize = SetFontSize(GetClientWidth());

    GlobalProperties.GetClientWidth = GetClientWidth;
    GlobalProperties.HtmlFontSize = HtmlFontSize;
}