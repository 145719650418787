import { request } from "@/utils/service";

export function emailSendCode(data){
    return request({
        url: "/api/account/code/email/send",
        method: "post",
        data
    })
}

export function emailLogin(data){
    return request({
        url: "/api/account/code/email/login",
        method: "post",
        data
    })
}

export function weChatLogin(data){
    return request({
        url: "/api/account/wechat/login",
        method: "post",
        data
    })
}


export function getUserInfoByUid(data){
    return request({
        url: "/api/account/get/user",
        method: "post",
        data
    })
}