import router from "@/router"
import { storeToRefs } from "pinia";

import { useRoomStoreHook } from '@/store/modules/room'
import { useAudioStoreHook } from '@/store/modules/audio'
import { useUserStoreHook } from '@/store/modules/user'
import { usePkpStoreHook } from '@/store/modules/pkp'
import { useSocketStoreHook } from '@/store/modules/socket'

import { RouterPrevLoad } from './preloadImg'

router.beforeEach(async (to, _form, next) => {
    const userStore = useUserStoreHook(),
        { UserInfo, Token } = storeToRefs(userStore);

    const pkpStore = usePkpStoreHook();
    const audioStore = useAudioStoreHook()
    const socketStore = useSocketStoreHook();
    const roomStore = useRoomStoreHook();


    pkpStore.InitData()

    if(to.path !== "/room" && to.path !== "/"){
        if(to.meta && to.meta.title){
            document.title = to.meta.title;
        }

        roomStore.InitRoomData()
        socketStore.ClearKeepLive()
        audioStore.SetIsCanIUseYinYue(false)
        audioStore.AudioGameBgBGMAction(false);
        audioStore.SetIsCanIUseYinXiao(true)
    }

    if(to.path === "/room" || to.path === "/over"){
        // audioStore.SetIsCanIUseYinYue(true)
        audioStore.SetIsCanIUseYinXiao(true)
    }

    if(to.path !== "/"){
        if(to.path === "/hallloading" || to.path === "/login"){
            next()
        }

        if(!UserInfo.value || !UserInfo.value.uid || !Token.value){
            next("/");
        } else {
            RouterPrevLoad({
                UserInfo
            });
            next();
        }
    } else {
        if(UserInfo.value && Token.value){
            if(!isNaN(Number(to.query?.kid || undefined))){
                next({
                    path: "/hallloading",
                    query: {
                        kid: to.query?.kid
                    }
                });
            }
            next("/hallloading")
        }
        next();
    }
    
});

router.afterEach(async (to, _form, next) => {
    const userStore = useUserStoreHook(),
        { UserInfo, Token } = storeToRefs(userStore);

    const socketStore = useSocketStoreHook();
    
    if(to.path !== "/" && to.path !== "/hallloading"){
        if(UserInfo.value && Token.value){
            await socketStore.InitSocket();
        }
    }
})