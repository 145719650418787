import { getStyle } from "@/utils"

function init(){
    const defaultScale = 1.1;

    const CacheMap = new Map();

    function touchStart(e){
        let cacheTransform = "";
        let scaleValue = defaultScale;
        if(CacheMap.has(this)){
            const { scale, transform } = CacheMap.get(this)
            scaleValue = scale;
            cacheTransform = transform;
        }

        this.style.transition = "transform 0.1s linear";
        // this.style.transform = `scale(${ value })`;
        this.style.transform = `${ cacheTransform } scale(${ scaleValue })`;
    }

    function touchEnd(e){
        let cacheTransition = "";
        if(CacheMap.has(this)){
            const { transition } = CacheMap.get(this)
            cacheTransition = transition;
        }

        this.style.transform = "";
        this.style.transition = cacheTransition;
    }

    return {
        mounted(el, binding, vnode){
            if(!CacheMap.has(el) && binding.value !== 0){
                const cacheTransform = getStyle(el, 'transform');
                const cacheTransition = getStyle(el, 'transition');

                CacheMap.set(el, {
                    scale: binding.value || defaultScale,
                    transform: (cacheTransform === "none" || cacheTransform === null) ? "" : cacheTransform,
                    transition: (cacheTransform === "none" || cacheTransform === null) ? "" : cacheTransition
                })
            }
            el.addEventListener("touchstart", touchStart, false);
            el.addEventListener("touchend", touchEnd, false);
        },
        beforeUnmount(el){
            if(CacheMap.has(el)){
                CacheMap.delete(el);
            }
            el.removeEventListener("touchstart", touchStart, false);
            el.removeEventListener("touchend", touchEnd, false);
        }
    }
}

export default init()