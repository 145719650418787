<script setup>

    const IsShow = ref(false);

    const TipText = ref("默认提示内容");

    const show = (str, time = 3000) => {
        TipText.value = str;
        IsShow.value = true;

        setTimeout(() => {
            IsShow.value = false;
        }, time);
    };

    const hide = () => (IsShow.value = false);

    defineExpose({
        show,
        hide,
    });
</script>

<template>
    <Teleport to="body">
        <Transition name="fade">
            <div v-if="IsShow" class="top-notice">
                <img src="/image/room/top-notice.png"/>
                <div class="txt">{{ TipText }}</div>
            </div>
        </Transition>
    </Teleport>
</template>

<style lang="scss" scoped>
    @import "@/assets/css/global.scss";

    .top-notice {
        position: fixed;
        z-index: 200;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        
        width: 1.9rem;
        height: 0.5rem;
        object-fit: contain;
        color: #fff;
        font-size: 0.16rem;

        display: flex;
        justify-content: center;
        align-items: center;

        img{
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
        }

        .txt{
            // width: 80%;
            display: inline-block;
            @include lineOne;
        }
    }

    
    .fade-enter-from {
        opacity: 0;
        transform: translate(-50%, -100%);
    }
    .fade-enter-active {
        transition: all 500ms linear;
    }
    .fade-enter-to {
        opacity: 1;
        transform: translate(-50%, 0);
    }

    .fade-leave-from {
        opacity: 1;
        transform: translate(-50%, 0);
    }
    .fade-leave-active {
        transition: all 500ms linear;
    }
    .fade-leave-to {
        opacity: 0;
        transform: translate(-50%, -100px);
    }
</style>