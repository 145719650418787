import { createApp } from 'vue'
import Toast from './index.vue'

let instance;

export function install (app) {
    const Vnode = createApp(Toast)

    const oFrag = document.createDocumentFragment();
    instance = Vnode.mount(oFrag);
}

export function ShowToast (str, time) {
    instance?.show(str, time)
}