import store from "@/store"
import { computed, shallowRef } from 'vue';
import { judgeCardType, generateCardType, getCardValue } from '@/utils/pkpType'
import { ServerPkpFormat, PkpDataFormatDuns, timer16ByWorker, arraysAreEqual } from "@/utils";
import { cloneDeep } from 'lodash-es'
import { useRoomStore } from "./room";
import { storeToRefs } from "pinia";
import workerTimer from "@/utils/intervalWorker";
 

export const usePkpStore = defineStore("pkp", () => {

    const roomStore = useRoomStore(),
        { RoomInfo } = storeToRefs(roomStore)

    const ArrangePkpTimer = ref(0);
    const IsTimeEnd = ref(false),
        DefaultTime = ref(null);
    let DefaultTimer = null;

    const IsShowPkpArrangeDialog = ref(false),
        IsShowPkpArrange = ref(false);

    const KaiShiRef = ref(null);

    const ServerPkp = ref(null);
    const CacheOriginCard = shallowRef(null);
    const OriginPkpData = ref(null);

    const AutoDunsPkpData = ref(null),
        AutoComputedPkpData = ref([]),
        AutoComputedPkpData0 = ref(null),
        AutoSelectPkpIdx = ref(0);

    const ManualSelectPkpData = ref([]),
        ManualDunsPkpData = ref([]);

    const SuperCard = ref(null);

    const AutoRenderPkpData = computed(() => {
        const idx = AutoSelectPkpIdx.value;

        if(idx === 0){
            return AutoDunsPkpData.value;
        } else if(idx === -1){
            return SuperCard.value.DunCard;
        } else {
            return AutoComputedPkpData.value[idx - 1]
        }
    });

    function InitData(){
        (DefaultTimer) && (workerTimer.clearInterval(DefaultTimer));
        ArrangePkpTimer.value = 0;
        IsTimeEnd.value = false;
        DefaultTime.value = RoomInfo.value?.room_introduction?.organize_cards_times || null;
        DefaultTimer = null;
        IsShowPkpArrangeDialog.value = false,
        IsShowPkpArrange.value = false;
        KaiShiRef.value = null;
        ServerPkp.value = null;
        CacheOriginCard.value = null;
        OriginPkpData.value = null;
        AutoDunsPkpData.value = null;
        AutoComputedPkpData.value = [];
        AutoSelectPkpIdx.value = 0;
        ManualSelectPkpData.value = [];
        ManualDunsPkpData.value = [];
        SuperCard.value = null;
    }

    function ProgressTimeRun(){
        (DefaultTimer) && (workerTimer.clearInterval(DefaultTimer));
        if(DefaultTime.value === null){
            console.error("倒计时时间是null", DefaultTime.value)
            return
        }
        ArrangePkpTimer.value = DefaultTime.value;
        console.log("开始倒计时:", ArrangePkpTimer.value, DefaultTime.value)
        IsTimeEnd.value = false;

        DefaultTimer = workerTimer.setInterval(() => {
            ArrangePkpTimer.value -= 1;
            if (ArrangePkpTimer.value <= 0) {
                workerTimer.clearInterval(DefaultTimer);
                DefaultTimer = null;

                console.log("倒计时结束", ArrangePkpTimer.value);
                IsTimeEnd.value = true;

                const timeouter = workerTimer.setTimeout(() => {
                    workerTimer.clearTimeout(timeouter)
                    IsTimeEnd.value = false;
                }, 500)
            };
        }, 1000);
    }

    function setDefaultTime(value){
        DefaultTime.value = value;
    }

    function SetServerPkp(opt){
        const { pkpData, combinationList, superCardsType, time } = opt;
        const { combinations_normal, combinations_super } = combinationList;
        console.time("牌初始化计算")
        
        SuperCard.value = null;
        ServerPkp.value = pkpData;
        setDefaultTime(time);

        CacheOriginCard.value = ServerPkpFormat(pkpData).sort((a, b) => a.point - b.point)
        OriginPkpData.value = cloneDeep(CacheOriginCard.value);


        // AutoComputedPkpData.value = generateCardType(OriginPkpData.value, 6)

        const tpyeNomalList = combinations_normal.map((item) => {
            return [
                formatCombinationList(item.front_res),
                formatCombinationList(item.middle_res),
                formatCombinationList(item.behind_res),
            ]
        });
        
        if(combinations_super.super_cards_res.type !== 0){ // 有特殊牌
            const paiArr = combinations_super.super_cards_res.cards_ids.map((cardId) => {
                return {
                    ...OriginPkpData.value.find((data) => {
                        return data.serverInfo.id === cardId;
                    }),
                    superTypeNum: combinations_super.super_cards_res.type,
                }
            });

            const DunCard = [
                {
                    paiArr: paiArr.slice(0, 3),
                    superCard: true,
                    superTypeNum: combinations_super.super_cards_res.type,
                    paiType: combinations_super.super_cards_res.type_name
                },
                {
                    paiArr: paiArr.slice(3, 8),
                    superCard: true,
                    superTypeNum: combinations_super.super_cards_res.type,
                    paiType: combinations_super.super_cards_res.type_name
                },
                {
                    paiArr: paiArr.slice(8, 13),
                    superCard: true,
                    superTypeNum: combinations_super.super_cards_res.type,
                    paiType: combinations_super.super_cards_res.type_name
                },
            ]

            SuperCard.value = {
                ...combinations_super.super_cards_res,
                DunCard,
            }
            console.log("SuperCard.value", SuperCard.value);
        }

        AutoComputedPkpData.value = tpyeNomalList.slice(1);
        AutoComputedPkpData0.value = tpyeNomalList[0];
        AutoDunsPkpData.value = AutoComputedPkpData0.value;
        // AutoDunsPkpData.value = combinations_super.super_cards_res.type !== 0 ? SuperCard.value.DunCard : AutoComputedPkpData0.value
        combinations_super.super_cards_res.type !== 0 ? SetAutoSelectPkpIdx(-1) : SetAutoSelectPkpIdx(0)
        // SetAutoSelectPkpIdx(0)

        SetIsShowPkpArrangeDialog(true);

        console.timeEnd("牌初始化计算")

        function formatCombinationList(item){
            const paiArr = item.cards_ids.map((cardId) => {
                return OriginPkpData.value.find((data) => {
                    return data.serverInfo.id === cardId;
                })
            });

            const paiType = item.type_name

            return {
                paiType, paiArr
            }
        }
    }

    function SortOriginPkpData (){
        if(OriginPkpData.value.length > 0){
            const res = OriginPkpData.value.sort((a, b) => {
                return getCardValue(a.num) - getCardValue(b.num);
            })
            OriginPkpData.value = res;
        }
    }

    function SetAutoDunsPkpData(value){
        console.log("SuperCard?.value?.cards_ids", SuperCard?.value?.cards_ids);
        
        if(SuperCard?.value?.cards_ids){
            const cardIds = value?.map((item) => {
                return item?.paiArr?.map((card) => card?.serverInfo?.id)
            })?.flat(1) || [];

            const result = arraysAreEqual(cardIds, (SuperCard?.value?.cards_ids || []))
            if(result){
                SetAutoSelectPkpIdx(-1)
                AutoDunsPkpData.value = AutoComputedPkpData0.value;
                return
            }
            // AutoDunsPkpData.value = result ? SuperCard.value.DunCard : value
        }

        AutoDunsPkpData.value = value
    }
    

    function SetAutoSelectPkpIdx(value){
        AutoSelectPkpIdx.value = value;
    }

    function SetIsShowPkpArrangeDialog(value){
        IsShowPkpArrangeDialog.value = value;
    }

    function SetIsShowPkpArrange(value){
        IsShowPkpArrange.value = value;
    }

    function SetManualSelectPkpData(value){
        ManualSelectPkpData.value = value;
    }

    function SetOriginPkpData(value){
        OriginPkpData.value = value;
    }

    function SetManualDunsPkpData(value){
        ManualDunsPkpData.value = value;
    }

    return {
        IsShowPkpArrangeDialog, IsShowPkpArrange, CacheOriginCard, SuperCard,
        ServerPkp,
        OriginPkpData,
        AutoDunsPkpData,
        AutoRenderPkpData,
        AutoComputedPkpData,
        AutoComputedPkpData0,
        AutoSelectPkpIdx,
        ManualSelectPkpData,
        ManualDunsPkpData,
        ArrangePkpTimer, IsTimeEnd, DefaultTime, KaiShiRef,

        InitData,
        SetServerPkp,
        setDefaultTime,
        SetAutoDunsPkpData,
        SetAutoSelectPkpIdx,
        SetIsShowPkpArrangeDialog,
        ProgressTimeRun,
        SetManualSelectPkpData,
        SetOriginPkpData,
        SetManualDunsPkpData,
        SortOriginPkpData,
        SetIsShowPkpArrange,
    }
});


export function usePkpStoreHook(){
    return usePkpStore(store);
}