import { H5ServerPublicKey } from '@/config/rsa-aes'
import CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt";

export function generateAesKey() {  
    
    let number = '';  
    while (number.length < 16) {  
        number += Math.floor(Math.random() * 10).toString();  
    }  
    return number;
}  
    
export function encryptDataWithAes(data, key) {
    if (typeof data !== 'string') {
        data = JSON.stringify(data)
    }
    // console.log(`AES加密 key, data`, key, data);
    
    // AES加密
    const result = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(key), // 向量。使用CBC模式时，需要使用向量。
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding // 偏移量。使用非补码方式时，需要使用ZeroPadding。默认为PKCS5Padding。
    })
    // base64转码
    return CryptoJS.enc.Base64.stringify(result.ciphertext)
}  
  
export function encryptAesKeyWithRsa(key) {  
    // console.log(`RSA加密 key, data`, key, H5ServerPublicKey);

    const encryptTool = new JSEncrypt()
    encryptTool.setPublicKey(H5ServerPublicKey)
    return encryptTool.encrypt(key)
}