import { request } from "@/utils/service";

/**
 * @param uid: 5000xxx
 * @param room_id: 4
 * @returns 
 */
export function joinRoom(data){
    return request({
        url: "/api/room/v1/join/room",
        method: "post",
        data
    })
}

/**
 * @param uid 
 * @param room_id
 * @param user_keep (keep_type, index)
 * @returns 
 */
export function heartKeepLive(data, headers){
    return request({
        url: "/api/room/v1/user/keep/live",
        method: "post",
        data,
        headers
    })
}


/**
 * @param uid 
 * @param room_id
 * @param position
 * @returns 
 */
export function joinDesk(data){
    return request({
        url: "/api/room/v1/player/join/water13",
        method: "post",
        data
    })
}

export function leaveDesk(data){
    return request({
        url: "/api/room/v1/player/leave/water13",
        method: "post",
        data
    })
}

/**
 * @param uid 
 * @param room_id
 * @param position
 * @returns 
 */
export function readyPlay(data){
    return request({
        url: "/api/room/v1/player/ready/water13",
        method: "post",
        data
    })
}


/**
 * @param uid 
 * @param room_id
 * @param position
 * @param cards ( id, card_name, card_type, point ) 
 * @returns 
 */
export function confirmSendCard(data){
    const { uid, room_id, position, cards, super_cards_type } = data;
    
    return request({
        url: "/api/room/v1/player/confirm/water13",
        method: "post",
        data: {
            uid, room_id, position, cards, super_cards_type
        }
    })
}


export function getRoomDetails(data) {
    return request({
        url: "/api/room/v1/get/room/details",
        method: "post",
        data
    })
}

export function leaveRoom(data){
    return request({
        url: "/api/room/v1/leave/room",
        method: "post",
        data
    })
}

export function getImResource(data){
    return request({
        url: "/api/room/v1/resource/list",
        method: "post",
        data,
    })
}

export function sendImMsg(data){
    return request({
        url: "/api/room/v1/resource/send",
        method: "post",
        data,
    })
}


export function createRoom(data){
    return request({
        url: "/api/order/create/room",
        method: "post",
        data,
    })
}


export function agianSendCard(data){
    return request({
        url: "/api/room/v1/send/cards/again",
        method: "post",
        data,
    })
}

export function roomPkStats(data){
    return request({
        url: "/api/room/v1/get/room/pkStats",
        method: "post",
        data,
    })
}