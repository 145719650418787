
import { SeverCardType, PkpTypeImg } from '@/config/menu-config'
import { v4 } from "uuid";

export function HasDebug(){
    // 获取当前的 URL  
    const currentUrl = window.location.href;  
    
    // 创建一个 URL 对象（包含当前 URL）  
    const url = new URL(currentUrl);  
    
    // 获取查询参数对象  
    const params = new URLSearchParams(url.search);  
    
    // 检查是否有 'debug' 参数并且值为 'true'  
    const hasDebugTrue = params.get('debug') === 'true';
    
    return hasDebugTrue
}

export function returnPkpTypeUrlImg (name){
    return dynamicPublicForCdnUrl(`/image/pkp-type/${ PkpTypeImg[name] }`);
}

export function ServerPkpFormat(pkpArr){
    return pkpArr.map((item) => {
        return {
            serverInfo: {...item},
            card_type: item.card_type,
            point: item.point,
            pidx: v4(),
            num: item.value,
            type: SeverCardType[item.card_type]    
        }
    })
}

export function PkpDataFormatDuns(pkpArr){
    return [
        pkpArr.slice(0, 3),
        pkpArr.slice(3, 8),
        pkpArr.slice(8),
    ]
}


export function dynamicPublicForCdnUrl(fileUrl){
    const { MODE, DEV, VITE_PUBLIC_PATH, VITE_IS_USE_CDN } = import.meta.env;

    if(VITE_IS_USE_CDN !== true || MODE === 'development') { // 开发环境
        return fileUrl;
    }
 
    return `${VITE_PUBLIC_PATH}${fileUrl}`;
}

export function preloadImage(url) {
    return new Promise(function(resolve, reject) {
        var img = new Image();
        img.onload = function() {
            resolve(img);
        };
        img.onerror = function() {
            reject(new Error('Could not load image at ' + url));
        };
        img.src = url;
    });
}

export function useDebounce(fn, delay) {
    let timer;

    return (...args) => {
        if(timer) {
            clearTimeout(timer);
            timer = null;
        }

        timer = setTimeout(() => {
            fn(...args);
            clearTimeout(timer);
            timer = null;
        }, delay);
    }
}

export function delStorage(key){
    localStorage.removeItem(key);
}

export function setStorage(key, data){

    let result;
    if(typeof data === "object" && data !== null){
        result = JSON.stringify(data);
    } else {
        result = data;
    }

    localStorage.setItem(key, result);
}

export function getStorage(key){
    let result = localStorage.getItem(key);
    try{
        const resData = JSON.parse(result);
        result = resData;
    } catch(e) {
        
    }

    return result;
}

const EventMap = new Map();
export function setTransitionEnd(targetEl, callback = null, time = null){
    return new Promise((resolve, reject) => {
        let setTimer = null;
        
        targetEl.addEventListener("transitionend", TransitionEndEvent, false);
        EventMap.set(targetEl, TransitionEndEvent);
        console.log(EventMap);


        time && (setTimer = setTimeout(() => {
            targetEl.removeEventListener("transitionend", TransitionEndEvent, false);
            callback && callback(targetEl);
            EventMap.delete(targetEl);
            console.log(EventMap);
            console.log("时间器内cleartTimeout", setTimer, time);
            
            setTimer && clearTimeout(setTimer)
            resolve(true);
        }, time + 50))
        
        function TransitionEndEvent(e){
            if(EventMap.has(e.target)){
                console.log("动画内cleartTimeout", setTimer, time);
                
                setTimer && clearTimeout(setTimer);
                callback && callback(targetEl);
                targetEl.removeEventListener("transitionend", TransitionEndEvent, false);
                EventMap.delete(e.target);
                console.log(EventMap);
                resolve(true);
            }
        }
    })
}

export function setAnimationEnd(targetEl, callback = null, time = null){
    return new Promise((resolve, reject) => {
        let setTimer = null;
        
        targetEl.addEventListener("animationend", AnimationEndEvent, false);
        EventMap.set(targetEl, AnimationEndEvent);
        console.log(EventMap);

        time && (setTimer = setTimeout(() => {
            targetEl.removeEventListener("animationend", AnimationEndEvent, false);
            callback && callback(targetEl);
            EventMap.delete(targetEl);
            console.log(EventMap);
            console.log("时间器内清楚时间", setTimer, time);
            
            setTimer && clearTimeout(setTimer)
            resolve(true);
        }, time + 50))
        
        console.log("animation时间", setTimer, time);

        function AnimationEndEvent(e){
            if(EventMap.has(e.target)){
                console.log("动画内cleartTimeout", setTimer);
                
                setTimer && clearTimeout(setTimer);
                callback && callback(targetEl);
                targetEl.removeEventListener("animationend", AnimationEndEvent, false);
                EventMap.delete(e.target);
                console.log(EventMap);
                resolve(true);
            }
        }
    })
}


export function findParentWithClass(el, className, num) {  
    // 检查当前元素是否为null（即已经到达根元素但未找到）  
    if (!el || num <= 0) {  
        return null;  
    }  
  
    // 检查当前元素是否包含指定的类名  
    if (el.classList.contains(className)) {  
        return el;  
    }  
  
    // 递归调用自身，查找当前元素的父元素  
    return findParentWithClass(el.parentElement, className, num - 1);  
}  


export function timestampToDateTime(timestampInSeconds) {  
    const timestampInMilliseconds = timestampInSeconds * 1000;  
    const date = new Date(timestampInMilliseconds);  
  
    const year = date.getFullYear();  
    const month = String(date.getMonth() + 1).padStart(2, '0');  
    const day = String(date.getDate()).padStart(2, '0');  
    const hours = String(date.getHours()).padStart(2, '0');  
    const minutes = String(date.getMinutes()).padStart(2, '0');  
    const seconds = String(date.getSeconds()).padStart(2, '0');  
  
    return {  
        year,  
        month,  
        day,  
        hours,  
        minutes,  
        seconds  
    };  
}

export function getStyle(element, property) {  
    if (window.getComputedStyle) {  
        // 对于现代浏览器，使用 getComputedStyle  
        return window.getComputedStyle(element)[property];  
    } else if (element.currentStyle) {  
        // 对于旧版 IE 浏览器，使用 currentStyle  
        return element.currentStyle[property];  
    } else {  
        // 如果都不支持，则返回一个默认值或进行错误处理  
        return null; // 或抛出错误  
    }  
}


export function arraysAreEqual(arr1, arr2) {  
    // 如果数组长度不同，直接返回 false  
    if (arr1.length !== arr2.length) {  
        return false;  
    }  

    // 遍历数组，比较每个元素是否相同  
    for (let i = 0; i < arr1.length; i++) {  
        if (arr1[i] !== arr2[i]) {  
            return false;  
        }  
    }  

    // 如果所有元素都相同，返回 true  
    return true;  
}


export function copyToClipboard(text) {  
    return new Promise(async (resolve, reject) => {
        if (navigator.clipboard) {  
            try {  
                await navigator.clipboard.writeText(text);  
                resolve(true)
            } catch (err) {  
                console.error('Failed to copy text: ', err);  
                fallbackCopyToClipboard(text); // Try fallback if Clipboard API fails  
            }  
        } else {  
            fallbackCopyToClipboard(text);  
        }  
      
        function fallbackCopyToClipboard(text) {  
            const textArea = document.createElement("textarea");  
            textArea.value = text;  
            textArea.style.position = "fixed";  // Avoid scrolling to bottom  
            textArea.style.top = "-9999px";  
          
            document.body.appendChild(textArea);  
            textArea.focus();  
            textArea.select();  
          
            try {  
                const successful = document.execCommand('copy');  
                const msg = successful ? 'successful' : 'unsuccessful';  
                resolve(true)
            } catch (err) {  
                console.error('Fallback failed to copy text: ', err);  
                reject(false)
            }  
            document.body.removeChild(textArea);  
        }  
    })
    
}  


const setup = () => {
    let timerId;
  
    let interval = 16.6;
  
    self.onmessage = (e) => {
      if (e.data.event === "start") {
        self.clearInterval(timerId);
        timerId = self.setInterval(() => {
          self.postMessage({});
        }, interval);
      }
  
      if (e.data.event === "stop") {
        self.clearInterval(timerId);
      }
    };
};
  
const createWorker = () => {
    const blob = new Blob([`(${setup.toString()})()`]);
    const url = URL.createObjectURL(blob);
    return new Worker(url);
};
  
const handlerMap = new Map();
    let runCount = 1;

    const worker = createWorker();
    worker.onmessage = () => {
        runCount += 1;
        for (const [k, v] of handlerMap.entries()) {
            if (runCount % k === 0) {
                v.forEach((fn) => fn());
            }
        }
    };
  /**
   * 16.6ms 执行一次回调
   */
  export const timer16ByWorker = (handler, time = 1) => {
    const fns = handlerMap.get(time) ?? new Set();
    fns.add(handler);
    handlerMap.set(time, fns);
  
    if (handlerMap.size === 1 && fns.size === 1) {
      worker.postMessage({ event: "start" });
    }
  
    console.log("timer16ByWorkerMap", handlerMap);
    return () => {
      fns.delete(handler);
      if (fns.size === 0) handlerMap.delete(time);
      if (handlerMap.size === 0) {
        runCount = 0;
        worker.postMessage({ event: "stop" });
        console.log("timer16ByWorkerMap", handlerMap);
      }
    };
  };