const SYSTEM_NAME = "13water"

/** 缓存数据时用到的 Key */
const CacheKey = {
  TOKEN: `${SYSTEM_NAME}-token-key`,
  USER_INFO: `${SYSTEM_NAME}-user-info`,
  ROOM_INFO: `${SYSTEM_NAME}-room-info`,
  PLAYER_ARRAY: `${SYSTEM_NAME}-player-array`,
  AUDIO_CONFIG: `${SYSTEM_NAME}-audio-config`,
  // CONFIG_LAYOUT: `${SYSTEM_NAME}-config-layout-key`,
  // SIDEBAR_STATUS: `${SYSTEM_NAME}-sidebar-status-key`,
  // ACTIVE_THEME_NAME: `${SYSTEM_NAME}-active-theme-name-key`,
  // VISITED_VIEWS: `${SYSTEM_NAME}-visited-views-key`,
  // CACHED_VIEWS: `${SYSTEM_NAME}-cached-views-key`,
}

export default CacheKey
