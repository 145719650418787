import { createApp } from 'vue'
import App from './App.vue'
import store from "@/store"
import router from "@/router"
import "@/router/permission"

import { setAppGlobalProperties } from "@/config/appConfig"
import { setDirectives } from '@/directives'
import { installGlobalComp } from '@/libs'


import "normalize.css"
import "@/assets/css/reset.css"
import "@/assets/css/global.scss"
import '@/utils/vconsole'

import Vue3TouchEvents from "vue3-touch-events";

const app = createApp(App)
app.use(router)
app.use(store)
app.use(Vue3TouchEvents)
setAppGlobalProperties(app);
setDirectives(app);
installGlobalComp(app);

router.isReady().then(() => {
    app.mount('#app')
})