

export const Audio = {
    GameBg: "Game-BG-BGM.mp3",          // 背景音乐
    BtnClickBGM: "Btn-Click.mp3",       // 按钮点击
    RenwuShoot: "Renwu-Shoot.mp3",      // 人物叫喊（打枪）
    GunShoot: "Gun-Shoot.mp3",          // 枪击声
    PkCardStart: "Pk-Card-Start.mp3",   // 比牌开始(叮)
    ArrangeCardStar: "Arrange-Card.mp3",    // 开始进入理牌阶段 手动理牌（特效）

    ZhunBei: "Zhun-Bei.mp3",            // 准备
    HasPlayerEndArrage: "Has-Player-EndArrage.mp3", // 有玩家理牌完成(理牌咯)
    SpecialRose: "Special-Rose.mp3",    // 特殊牌-玫瑰花 音效
    HomeRun: "Home-Run.mp3",            // 全垒打音效

    // pk-start
    DuiZi: "Dui-Zi.mp3",                // 一对
    LiangDui: "Liang-Dui.mp3",          // 两对
    SanTiao: "San-Tiao.mp3",            // 三条
    HuLu: "Hu-Lu.mp3",                  // 葫芦
    ShunZi: "Shun-Zi.mp3",              // 顺子
    TongHua: "Tong-Hua.mp3",            // 同花
    WuLong: "Wu-Long.mp3",              // 乌龙
    TieZhi: "Tie-Zhi.mp3",              // 铁支
    TongHuaShun: "Tong-Hua-Shun.mp3",    // 同花顺

    LiuDuiBan: "Liu-Dui-Ban.mp3", // 超级牌  (六对半)
    SanShunZi: "San-Shun-Zi.mp3", // 超级牌  (三顺子)
    SanTongHua: "San-Tong-Hua.mp3", // 超级牌  (三同花)
}

export const PkpTypeAudio = {
    "同花顺": "TongHuaShun",
    "铁支": "TieZhi",
    "葫芦": "HuLu",
    "同花": "TongHua",
    "顺子": "ShunZi",
    "三条": "SanTiao",
    "两对": "LiangDui",
    "一对": "DuiZi",
    "乌龙": "WuLong"
}

export const SuperCardAudio = {
    15: null, // 超级一条龙  (13张牌)
    16: "LiuDuiBan", // 超级牌  (六对半)
    17: "SanShunZi", // 超级牌  (三顺子)
    18: "SanTongHua", // 超级牌  (三同花)
}