
const baseURL = import.meta.env.VITE_APP_BASE_WS;
const EventTypes = ['open', 'close', 'message', 'error', 'reconnect'];
// const DEFAULT_CHECK_TIME = 55 * 1000; // 心跳检测的默认时间
// const DEFAULT_CHECK_COUNT = 3; // 心跳检测默认失败重连次数
// const DEFAULT_CHECK_DATA = { Type: 1, Parameters: ['alive'] }; // 心跳检测的默认参数 - 跟后端协商的
const CLOSE_ABNORMAL = 1006; // WebSocket非正常关闭code码

class EventMap {
    deps = new Map();
    depend(eventType, callback) {
        this.deps.set(eventType, callback);
    }
    notify(eventType, event) {
        if (this.deps.has(eventType)) {
            this.deps.get(eventType)(event);
        }
    }
}


function CreateSocket(options, dep, reconnectCount = 0) {

    let _baseURL = baseURL
    const { url = null, path, protocols, query = {}, greet = null, customBase = null } = options;

    const _queryParams = Object.keys(query).reduce((str, key) => {
        if (typeof query[key] !== 'object' && typeof query[key] !== 'function') {
            return str += str.length > 0 ? `&${key}=${query[key]}` : `${key}=${query[key]}`;
        } else {
            return str;
        }
    }, '');

    if (customBase) {
        _baseURL = customBase
    }

    let wsUrl = `wss://${_baseURL}${path}?${_queryParams}`;
    if (url) {
        wsUrl = `wss://${url}?${_queryParams}`;
    }

    const socket = new WebSocket(wsUrl, protocols);

    let _this = {
        _currentOptions: options,
        _dep: dep,
        _reconnectCount: reconnectCount,
    };

    greet && (_this = merge(_this, {
        heartCheckData: greet
    }));
    

    function initSocket(){

        socket.onopen = function(e){
            _this._dep.notify("open", e);
        }

        socket.onclose = function (e) {
            _this._dep.notify('close', e);

            // 如果WebSocket是非正常关闭 则进行重连
            if (e.code === CLOSE_ABNORMAL) {
                console.log("socket 非正常关闭");
            }
        }

        socket.onerror = function (e) {
            _this._dep.notify('error', e);
        }

        socket.onmessage = function (e) {
            // 如果后端返回的是二进制数据
            if (e.data instanceof Blob) {
                const reader = new FileReader()
                reader.readAsArrayBuffer(e.data)
                reader.onload = (ev) => {
                    if (ev.target.readyState === FileReader.DONE) {
                        _this._dep.notify('message', ev.target?.result);
                    }
                }
            } else {
                // 处理普通数据
                try {
                    const _parseData = JSON.parse(e.data);
                    _this._dep.notify('message', _parseData);
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }

    // 发送消息
    _this.sendMessage = (data, options = {}) => {
        const { transformJSON = true } = options;
        let result = data;
        
        if (transformJSON) {
            result = JSON.stringify(data);
        }

        socket.send(result);
    }

    // 订阅事件
    _this.subscribe = (eventType, callback) => {
        if (typeof callback !== 'function') throw new Error('The second param is must be a function');
        if (!EventTypes.includes(eventType)) throw new Error('The first param is not supported');
        _this._dep.depend(eventType, callback);
    }
    
    // 开始心跳检测
    // function heartCheckStart() {
    //     _this.heartCheckInterval = setInterval(() => {
    //         if (socket.readyState === WebSocket.OPEN) {
    //             let transformJSON = typeof _this.heartCheckData === 'object'
    //             sendMessage(_this.heartCheckData, { transformJSON });
    //         } else {
    //             this.clearHeartCheck();
    //         }
    //     }, this.heartCheckTimeout)
    // }

    // 清除心跳检测
    // function clearHeartCheck() {
    //     clearInterval(_this.heartCheckInterval);
    // }

    // // 重置心跳检测
    // function resetHeartCheck() {
    //     clearInterval(_this.heartCheckInterval);
    //     heartCheckStart();
    // }

    initSocket()

    return _this;
}

// 默认的配置项
const defaultOptions = {
    url: '',
    protocols: '',
    query: {},
}
  
export const useSocket = (options = defaultOptions) => {
    if (!window.WebSocket) throw new Error('您的浏览器不支持WebSocket, 请更换浏览器!');

    const dep = new EventMap();
    const reconnectCount = 0;

    const SocketInstance = CreateSocket(options, dep, reconnectCount)
    console.log("🚀 ~ useSocket ~ SocketInstance:", SocketInstance)
    return SocketInstance;
}