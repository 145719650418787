import { createApp } from 'vue'
import Toast from './index.vue'

let instance;
export function install (app) {
    const Vnode = createApp(Toast)

    const oFrag = document.createDocumentFragment();
    instance = Vnode.mount(oFrag);
}

export function ShowJoinUserNotice (str, time) {
    console.log("str, time", instance, str, time);
    
    instance?.show(str, time)
}

