<script setup>
import { useRouter, useRoute } from "vue-router"

const router = useRouter();
const route = useRoute();

// if (!route.query?.debug || route.query?.debug == 'false') {
//   const ua = navigator.userAgent.toLowerCase();
//   if (ua.match(/MicroMessenger/i) !== "micromessenger") {
//     localStorage.clear();
//     setTimeout(() => {
//       router.replace({
//         path: "/"
//       })
//     }, 50)
//   }
// }
</script>


<template>
  <router-view />
</template>


<style scoped></style>
