import axios from "axios";
import { get, merge } from "lodash-es"
import { useUserStore, useUserStoreHook } from "@/store/modules/user";
import { useSocketStore } from "@/store/modules/socket";
import { ShowToast } from "@/libs/components/Notice";
import { HasDebug } from "@/utils"
import { generateAesKey, encryptDataWithAes, encryptAesKeyWithRsa } from "@/utils/rsaAes";

function logout() {
    useUserStore().loginOut();
    location.reload();
}

function ClearKeepLive(){
    useSocketStore().ClearKeepLive()
}

function createService() {
    const service = axios.create()
    const userStore = useUserStoreHook();
    
    // 请求拦截
    service.interceptors.request.use(
        (config) => {
            const method = config.method;
            // config.headers["session"] = userStore.Token;

            if(userStore?.UserInfo?.uid && userStore?.Token){
                const url = config.url;
                const params = new URLSearchParams({
                    uid: userStore?.UserInfo?.uid,
                    session: userStore?.Token,
                })
                config.url = `${url}?${params.toString()}`
            }


            switch(method.toLowerCase()){
                case "get":
                    // const url = config.url;
                    // const params = new URLSearchParams({
                    //     ...config.data,
                    //     session: userStore.Token,
                    // })
                    // config.url = `${url}?${params.toString()}`
                    break;
                case "post":
                    // config.data['session'] = userStore.Token
                    if(!HasDebug()){
                        console.log("加密前的数据", config.data, config);
                        const PostData = config.data;
                        const aesKeyHex = generateAesKey();  
                        const encryptedData = encryptDataWithAes(PostData, aesKeyHex);  
                        const encryptedAesKey = encryptAesKeyWithRsa(aesKeyHex);  
                        config.data = {
                            key: encryptedAesKey,
                            body: encryptedData
                        }
                        // console.log("随机字符串:", aesKeyHex);  
                        // console.log("Aes 加密 Data (Base64):", encryptedData);  
                        // console.log("Rsa 加密 Key (Base64):", encryptedAesKey);
                        // console.log("加密后的数据:", config.data, config);
                    }
                    break;
            }

            return config;
        },
        (error) => Promise.reject(error)
    )

    //响应拦截
    service.interceptors.response.use(
        (response) => {
            const apiData = response.data
            const responseType = response.request.responseType

            // 二进制数据直接返回
            if (responseType === "blob" || responseType === "arraybuffer") return apiData

            const code = apiData.code;
            if(code === undefined) {
                ShowToast(apiData?.msg, 6000)
                return Promise.reject((apiData ?? "异常接口"))
            }

            switch (code) {
                case 0:
                    return apiData
                case 401:
                    // Token 过期时
                    logout()
                    return
                case 201:
                    console.warn("201: 心跳定时器停止...")
                    ClearKeepLive()
                    return
                default:
                    ShowToast(apiData?.msg, 6000)
                    return Promise.reject((apiData ?? "异常接口"))
            }
        },
        (error) => {
            const status = get(error, "response.status")
            switch (status) {
                case 400:
                    error.message = "请求错误"
                    break
                case 401:
                    // Token 过期时
                    error.message = "Token过期"
                    logout()
                    break
                case 403:
                    error.message = "拒绝访问"
                    break
                case 404:
                    error.message = "请求地址出错"
                    break
                case 408:
                    error.message = "请求超时"
                    break
                case 500:
                    error.message = "服务器内部错误"
                    break
                case 501:
                    error.message = "服务未实现"
                    break
                case 502:
                    error.message = "网关错误"
                    break
                case 503:
                    error.message = "服务不可用"
                    break
                case 504:
                    error.message = "网关超时"
                    break
                case 505:
                    error.message = "HTTP 版本不受支持"
                    break
                default:
                    break
            }

            return Promise.reject(error)
        }
    )

    return service
}


function createRequest(service) {
    return function(config) {
        // const token = getToken()
        const defaultConfig = {
            headers: {
                // "Authorization": token ? `Bearer ${ token }` : undefined,
                "Content-Type": "application/json",
            },
            timeout: 10000,
            baseURL: import.meta.env.VITE_BASE_API,
            data: {}
        }

        const mergeConfig = merge(defaultConfig, config)
        return service(mergeConfig)
    }
}

const service = createService()
export const request = createRequest(service)