import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

export const constantRoutes = [
    {
      path: "/login",
      component: () => import("@/views/login"),
      meta: {
        title: "玖天乐游"
      }
    },
    {
      path: "/",
      component: () => import("@/views/loading/wxlogin"),
      meta: {
        title: "玖天乐游"
      }
    },
    {
      path: "/room",
      component: () => import("@/views/room"),
      meta: {
        title: "玖天乐游"
      }
    },
    {
      path: "/hall",
      component: () => import("@/views/hall"),
      meta: {
        title: "玖天乐游"
      }
    },
    {
      path: "/over",
      component: () => import("@/views/room/gameover"),
    },
    {
      path: "/hallloading",
      component: () => import("@/views/loading/hallloading"),
      meta: {
        title: "玖天乐游"
      }
    },
    {
      path: "/close",
      component: () => import("@/views/error/roomclose"),
    },
]

const router = createRouter({
    history:
        import.meta.env.VITE_ROUTER_HISTORY === "hash"
        ? createWebHashHistory(import.meta.env.VITE_PUBLIC_PATH)
        : createWebHistory(import.meta.env.VITE_PUBLIC_PATH),
    routes: constantRoutes
})

export function resetRouter() {
    try {
        router.getRoutes().forEach((route) => {
            const { name, meta } = route
            if (name && meta.roles?.length) {
                router.hasRoute(name) && router.removeRoute(name)
            }
        })
    } catch {
        window.location.reload()
    }
}

export default router