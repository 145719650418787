import store from "@/store"
import CacheKey from '@/config/cache-key'
import { defineStore } from "pinia";
import {Howl, Howler} from 'howler';
import { getStorage, setStorage, useDebounce, timer16ByWorker } from "@/utils"
import { Audio, PkpTypeAudio, SuperCardAudio } from "@/config/audio"
import workerTimer from '@/utils/intervalWorker'

const AudioPath = "/sound"

let soundBgmInstance = null;

function GetAudioInstance(type = {}, event = {}, opt = {}){
    
    const { audioType = null, url = null } = type;
    const { soundEnd } = (event || {});

    const mp3 = url ? url : `${AudioPath}/${Audio[audioType]}`;
    
    const soundBgm = new Howl({
        ...opt,
        src: [mp3],
        preload: true,
    });

    if(opt?.IsBGM){
        soundBgm.on('load',()=>{
            window.WeixinJSBridge ? window.WeixinJSBridge.invoke('getNetworkType', {},  ()=> {
                soundBgm.play();
            }, false) : soundBgm.play();
        })
    }

    soundBgm.on('end', ()=>{
        if(!opt?.IsBGM){
            setTimeout(() => {
                soundBgm.unload();
            }, 500)
        }
        soundEnd && soundEnd(true);
        console.log(`${AudioPath}/${mp3} 播放完毕`, soundEnd);
    })

    return soundBgm;
}

export const useAudioStore = defineStore("audio", () => {
    const AudioStorage = getStorage(CacheKey.AUDIO_CONFIG) || {
        YinYue: true,
        YinXiao: true
    };

    const IsCanIUseYinYue = ref(AudioStorage.YinYue),
        IsCanIUseYinXiao = ref(AudioStorage.YinXiao);

    function SetIsCanIUseYinYue(value){
        IsCanIUseYinYue.value = value;
        if(value){
            soundBgmInstance?.volume(1)
            // soundBgmInstance?.play();
        } else {
            soundBgmInstance?.volume(0)
            // soundBgmInstance?.stop();
        }

        setStorage(CacheKey.AUDIO_CONFIG, {
            YinYue: IsCanIUseYinYue.value,
            YinXiao: IsCanIUseYinXiao.value
        })
    }

    function SetIsCanIUseYinXiao(value){
        IsCanIUseYinXiao.value = value;
        
        setStorage(CacheKey.AUDIO_CONFIG, {
            YinYue: IsCanIUseYinYue.value,
            YinXiao: IsCanIUseYinXiao.value
        })
    }

    // 背景音乐
    function AudioGameBgBGM(){
        if(soundBgmInstance && soundBgmInstance?.playing()){
            console.warn("背景音乐正在播放中")
            // soundBgmInstance.seek(0);
            soundBgmInstance?.stop();
        }

        if(soundBgmInstance === null){
            soundBgmInstance = GetAudioInstance({
                audioType: "GameBg"
            }, null, {
                loop: true,
                volume: IsCanIUseYinYue.value ? 1 : 0,
                IsBGM: true
            });
        }
        soundBgmInstance.play();
    }

    function AudioGameBgBGMAction(value){
        if(value === true){
            soundBgmInstance?.play();
        } else {
            soundBgmInstance?.stop();
        }
    }
    // IM语音
    function IMAudioBGM(url){
        return new Promise((resolve) => {
            const soundBgm = GetAudioInstance({
                url
            }, {
                soundEnd: (value) => {
                    resolve(true);
                }
            }, {
                volume: IsCanIUseYinXiao.value ? 1 : 0,
            })
            soundBgm.play();
        })
    }

    // 牌型
    function PkpTypeNameAudioBGM(cardTypeName){
        return new Promise((resolve) => {
            const typeName = PkpTypeAudio?.[cardTypeName];
            if(typeName === null || !typeName){
                console.error(`牌型声音不存在: ${ cardTypeName }`, PkpTypeAudio, cardTypeName)
                resolve(true);
                return;
            }


            const soundBgm = GetAudioInstance({
                audioType: typeName
            }, {
                // soundEnd: (value) => {
                //     resolve(true);
                // }
            }, {
                volume: IsCanIUseYinXiao.value ? 1 : 0,
            })
            soundBgm.play();

            console.log("牌型呻吟", typeName, [ "TieZhi", "TongHuaShun" ].includes(typeName) ? (1) * 60 : (0.01 * 1) * 60);
            
            // const stopTimer = timer16ByWorker(() => {
            //     stopTimer()
            //     resolve(true)
            // }, [ "TieZhi", "TongHuaShun" ].includes(typeName) ? (1.5) * 60 : (0.3) * 60)

            const timer = workerTimer.setTimeout(() => {
                workerTimer.clearTimeout(timer)
                resolve(true)
            }, [ "TieZhi", "TongHuaShun" ].includes(typeName) ? 1500 : 100)
            console.log("时间- timer", timer);
            
        })
    }

    // 特殊牌音效
    function SpecialRoseAudioBGM(){
        return new Promise((resolve) => {
            const soundBgm = GetAudioInstance({
                audioType: "SpecialRose"
            }, {
                soundEnd: (value) => {
                    resolve(true);
                }
            }, {
                volume: IsCanIUseYinXiao.value ? 1 : 0,
            })
            soundBgm.play();
        })
    }

    // 特殊牌牌型
    function SpecialTxtAudioBGM(value){
        return new Promise((resolve) => {
            const typeName = SuperCardAudio?.[value];
            
            if(typeName === null || !typeName){
                console.error(`牌型声音不存在: ${ SuperCardAudio }`, SuperCardAudio, value)
                resolve(true);
                return;
            }


            const soundBgm = GetAudioInstance({
                audioType: typeName
            }, {
                // soundEnd: (value) => {
                //     resolve(true);
                // }
            }, {
                volume: IsCanIUseYinXiao.value ? 1 : 0,
            })
            soundBgm.play();
            const stopTimer = timer16ByWorker(() => {
                stopTimer()
                resolve(true)
            }, (1) * 60 )
        })
    }

    // 全垒打
    function HomeRunAudioBGM(value){
        return new Promise((resolve) => {

            const soundBgm = GetAudioInstance({
                audioType: "HomeRun"
            }, {
                soundEnd: (value) => {
                    resolve(true);
                }
            }, {
                volume: IsCanIUseYinXiao.value ? 1 : 0,
            })
            soundBgm.play();
        })
    }

    // 按钮点击
    function AudioBtnClickBGM(){
        return new Promise((resolve) => {
            // if(IsCanIUseYinXiao.value === false){
            //     resolve(true);
            //     return;
            // }

            const soundBgm = GetAudioInstance({
                audioType: "BtnClickBGM"
            }, {
                soundEnd: (value) => {
                    resolve(true);
                }
            }, {
                volume: IsCanIUseYinXiao.value ? 1 : 0,
            })
            soundBgm.play();
        })
    }

    function AudioReadyBtnBGM(){
        return new Promise((resolve) => {
            // if(IsCanIUseYinXiao.value === false){
            //     resolve(true);
            //     return;
            // }

            const soundBgm = GetAudioInstance({ audioType: "ZhunBei" }, {
                soundEnd: (value) => {
                    resolve(true);
                }
            }, {
                volume: IsCanIUseYinXiao.value ? 1 : 0,
            })
            soundBgm.play();
        })
    }

    function AudioRenwuShootBGM(){
        return new Promise((resolve) => {
            // if(IsCanIUseYinXiao.value === false){
            //     resolve(true);
            //     return;
            // }

            const soundBgm = GetAudioInstance({ audioType: "RenwuShoot" }, {
                soundEnd: (value) => {
                    resolve(true);
                }
            }, {
                volume: IsCanIUseYinXiao.value ? 1 : 0,
            })
            
            console.log("soundBgm", soundBgm);
            
            soundBgm.on('end', ()=>{
                resolve(true);
            })
            
            soundBgm.play();
        })
    }

    function AudioGunShootBGM(){
        return new Promise((resolve) => {
            // if(IsCanIUseYinXiao.value === false){
            //     resolve(true);
            //     return;
            // }

            const soundBgm = GetAudioInstance({ audioType: "GunShoot" }, {
                soundEnd: (value) => {
                    resolve(true);
                }
            }, {
                volume: IsCanIUseYinXiao.value ? 1 : 0,
            })
            soundBgm.play();
        })
    }

    function AudioPkCardStartBGM(){
        return new Promise((resolve) => {
            // if(IsCanIUseYinXiao.value === false){
            //     resolve(true);
            //     return;
            // }

            const soundBgm = GetAudioInstance({ audioType: "PkCardStart" }, {
                soundEnd: (value) => {
                    resolve(true);
                }
            }, {
                volume: IsCanIUseYinXiao.value ? 1 : 0,
            })
            soundBgm.play();
        })
    }

    function AudioArrangeCardStarBGM(){
        return new Promise((resolve) => {
            // if(IsCanIUseYinXiao.value === false){
            //     resolve(true);
            //     return;
            // }
            
            const soundBgm = GetAudioInstance({ audioType: "ArrangeCardStar" }, {
                soundEnd: (value) => {
                    resolve(true);
                }
            }, {
                volume: IsCanIUseYinXiao.value ? 1 : 0,
            })
            soundBgm.play();
        })
    }

    function AudioZhunBeiBGM(){
        return new Promise((resolve) => {
            // if(IsCanIUseYinXiao.value === false){
            //     resolve(true);
            //     return;
            // }
            
            const soundBgm = GetAudioInstance({ audioType: "ZhunBei" }, {
                soundEnd: (value) => {
                    resolve(true);
                }
            }, {
                volume: IsCanIUseYinXiao.value ? 1 : 0,
            })
            soundBgm.play();
        })
    }

    function AudioHasPlayerEndArrageBGM(){
        return new Promise((resolve) => {
            // if(IsCanIUseYinXiao.value === false){
            //     resolve(true);
            //     return;
            // }
            
            const soundBgm = GetAudioInstance({ audioType: "HasPlayerEndArrage" }, {
                soundEnd: (value) => {
                    resolve(true);
                }
            }, {
                volume: IsCanIUseYinXiao.value ? 1 : 0,
            })
            soundBgm.play();
        })
    }


    return {
        IsCanIUseYinYue, IsCanIUseYinXiao,
        AudioGameBgBGMAction,
        SpecialRoseAudioBGM,
        SpecialTxtAudioBGM,
        HomeRunAudioBGM,
        PkpTypeNameAudioBGM: useDebounce(PkpTypeNameAudioBGM , 100),
        IMAudioBGM: useDebounce(IMAudioBGM, 100),
        SetIsCanIUseYinYue: useDebounce(SetIsCanIUseYinYue ,100), 
        SetIsCanIUseYinXiao: useDebounce(SetIsCanIUseYinXiao ,100),
        AudioRenwuShootBGM: useDebounce(AudioRenwuShootBGM ,100),
        AudioGunShootBGM: useDebounce(AudioGunShootBGM ,100),
        AudioPkCardStartBGM: useDebounce(AudioPkCardStartBGM ,100),
        AudioArrangeCardStarBGM: useDebounce(AudioArrangeCardStarBGM ,100),
        AudioZhunBeiBGM: useDebounce(AudioZhunBeiBGM ,100),
        AudioGameBgBGM: useDebounce(AudioGameBgBGM ,100), 
        AudioBtnClickBGM: useDebounce(AudioBtnClickBGM ,100), 
        AudioReadyBtnBGM: useDebounce(AudioReadyBtnBGM ,100),
        AudioHasPlayerEndArrageBGM: useDebounce(AudioHasPlayerEndArrageBGM ,100),
    }
})


export function useAudioStoreHook(){
    return useAudioStore(store);
}