<script setup>
  import { ref, defineExpose } from "vue";

  const isShow = ref(false);
  const tipText = ref("默认提示内容");

  const show = (str, time = 3000) => {

    tipText.value = str;
    isShow.value = true;

    setTimeout(() => {
      isShow.value = false;
    }, time);

  };

  const hide = () => (isShow.value = false);
  
  defineExpose({
    show,
    hide,
  });
</script>

<template>
  <Teleport to="body">
    <div v-if="isShow" class="bottom-notice">
      <div class="txt">
        {{ tipText }}
      </div>
    </div>
  </Teleport>
</template>
<style lang="scss" scoped>
  @import "@/assets/css/global.scss";

  .bottom-notice {
    position: fixed;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 200;

    background-color: rgba(0, 0, 0, 0.8);
    padding: 0.1rem;
    box-sizing: border-box;
    border-radius: 5px;

    .txt{
      max-width: 2rem;
      max-height: 0.5rem;
      font-size: 0.14rem;
      color: #e7e7e7;
      @include lines(2);
    }
  }
</style>
