import { request } from "@/utils/service";

/**
 * @param uid: 5000xxx
 * @param room_id: 4
 * @returns 
 */
export function getSocketToken(data = {}){
    return request({
        url: "/api/socket/get/register/token",
        method: "post",
        data
    })
}