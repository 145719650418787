const CardTypeNum = ["乌龙", "一对", "两对", "三条",  "顺子", "同花", "葫芦", "铁支", "同花顺"];

export function IsPourWater(array){

    let max = {
        idx: null,
        card: null
    };

    for(let dun of array){
        const dunIdx = CardTypeNum.findIndex((item) => dun.paiType == item);

        if(max.idx === null || max.idx <= dunIdx){
            max.idx = dunIdx;
            max.card = dun.paiArr
            continue
        }

        if(max.idx > dunIdx){
            console.warn("倒水结果",max.idx > dunIdx, max.idx , dunIdx, max, dun);
            
            return true;
        }

        // if(max.idx == dunIdx){
            
        //     const maxPoints = max.card.reduce((total, num) => total + num.point, 0)
        //     const curPoints = dun.paiArr.reduce((total, num) => total + num.point, 0)

        //     console.log("maxPoints > curPoints", maxPoints, curPoints);
        //     if(maxPoints > curPoints){
        //         return true
        //     }
        // }
    }

    return false;
}

export function CardTypeColor(typeValue){
    if(["葫芦", "铁支", "同花顺", "一条龙", "六对半", "三顺子", "三同花"].includes(typeValue)){
        return "yellow"
    }

    return 'blue'
}

export function DunsCardType(type, typeValue){

    if(type === "touDun" && typeValue === "三条"){
        return {
            value: "三条冲头",
            color: "yellow"
        }
    }

    if(type === "zhongDun" && ["葫芦", "铁支", "同花顺"].includes(typeValue)){
        return {
            value: `中墩${typeValue}`, 
            color: "yellow"
        }
    }

    if(type === "diDun" && ["铁支", "同花顺"].includes(typeValue)){
        return {
            value:`${typeValue}`, 
            color: "yellow"
        }
    }

    return {
        value: typeValue,
        color: "blue"
    }
}

export function judgeCardType(array) {  
    function returnResult(dun){

        if (isFlushAndStraights(dun)) {  
            return `同花顺`;
        } else if (hasFourOfAKind(dun)) {  
            return `铁支`;
        } else if (hasThreeOfAKindAndPair(dun)) {  
            return `葫芦`;
        } else if (isFlush(dun)) {  
            return `同花`;
        } else if (_isStraight(dun)) {  
            return `顺子`;
        } else if (hasThreeOfAKind(dun)) {  
            return `三条`;
        } else if (hasTwoPairs(dun)) {  
            return `两对`;
        } else if (hasPair(dun)) {  
            return `一对`;
        } else {  
            return `乌龙`;
        } 
    }

    return array.reduce((newArr, dun, index) => {
        newArr.push({
            paiType: returnResult(dun),
            paiArr: dun
        })

        return newArr;
    }, []);
}

export function IsHasTargetCardType(array){
    const result = {
        "五同": false,
        "同花顺": false,
        "铁支": false,
        "葫芦": false,
        "同花": false,
        "顺子": false,
        "三条": false,
        "两对": false,
        "一对": false,
    }

    if(isFlushAndStraights(array)){
        result['同花顺'] = true;
    }

    if(hasFourOfAKind(array)){
        result['铁支'] = true;
    }

    if(hasThreeOfAKindAndPair(array)){
        result['葫芦'] = true;
    }

    if(isFlush(array)){
        result['同花'] = true;
    }

    if(_isHasStraights(array)){
        result['顺子'] = true;
    }

    console.log("array", array);
    
    if(hasThreeOfAKind(array)){
        result['三条'] = true;
    }

    if(hasTwoPairs(array)){
        result['两对'] = true;
    }

    if(hasPair(array)){
        result['一对'] = true;
    }

    return result;
}

// 列举各种 头、中、低 的牌型组合
export function generateCardType(array, resultLen = null){
    if(Array.isArray(array) && array.length != 13) {
        return false;
    }
    // 普通牌型
    // 同花顺：五张同花色的连续牌。                         5
    // 铁支（炸弹、四条）：四张相同点数的牌加任意一张单牌。   5    
    // 葫芦：三张相同点数的牌加一对相同点数的牌。            5
    // 同花：五张牌全部是同一花色，但不成顺子。              5
    // 顺子：五张牌点数连续的牌组。                         5
    // 三条：三张相同点数的牌。                             5, 3
    // 两对：两对点数相同的牌。                             5
    // 一对：两张点数相同的牌。                             5, 3
    // 散牌（单张、杂牌）：不成对的单张牌。                  5, 3


    const fiveCardsFn = [findStraightFlushes, findFourOfAKind, findFullHouses, findSameFlower, findStraight, findMaxTriplet, findMaxTwoPairs, findMaxOnePairs];
    const threeCardsFn = [findMaxTriplet, findMaxOnePairs];

    const originCards = array;

    const resultArr = [];
    let tempDuns = [{}, {}, {}];
    
    let checkPourWater;

    for(let diDunFn of fiveCardsFn){
        const diDunRes = diDunFn([...originCards]);
        if(diDunRes){
            const [ diDunType, diDunArr, diFilterArr ] = diDunRes;
            
            for(let zhongDunFn of fiveCardsFn){
                const zhongDunRes = zhongDunFn([...diFilterArr]);
                
                if(zhongDunRes){
                    const [ zhongDunType, zhongDunArr, zhongFilterArr ] = zhongDunRes;

                    checkPourWater = [
                        {
                            paiType: zhongDunType,
                            paiArr: zhongDunArr
                        },
                        {
                            paiType: diDunType,
                            paiArr: diDunArr
                        }
                    ];
                    if(IsPourWater(checkPourWater)){
                        console.log("倒水牌-过滤", checkPourWater);
                        continue
                    }

                    let IsNoTouDun = false;

                    for(let touDunFn of threeCardsFn) {
                        const touDunRes = touDunFn([...zhongFilterArr]);

                        if(touDunRes){
                            const [ touDunType, touDunArr, touFilterArr ] = touDunRes;

                            const diDunLen = 5 - diDunArr.length,
                                zhongDunLen = 5 - zhongDunArr.length,
                                touDunLen = 3 - touDunArr.length;

                            touFilterArr.sort((a, b) => getCardValue(a.num) - getCardValue(b.num));

                            let diDunArrRes = fillWhile(diDunLen, diDunArr, [...touFilterArr]);
                            let zhongDunArrRes = fillWhile(zhongDunLen, zhongDunArr, [...touFilterArr]);
                            let touDunArrRes = fillWhile(touDunLen, touDunArr, [...touFilterArr]);

                            tempDuns = [
                                {
                                    paiType: touDunLen == 3 ? "乌龙": touDunType,
                                    paiArr: touDunArrRes,
                                },{
                                    paiType: zhongDunLen == 5 ? "乌龙": zhongDunType,
                                    paiArr: zhongDunArrRes,
                                },{
                                    paiType: diDunLen == 5 ? "乌龙": diDunType,
                                    paiArr: diDunArrRes,
                                },
                            ];

                            if(IsPourWater(tempDuns)){
                                console.log("倒水牌-过滤", tempDuns);
                                continue
                            }

                            console.log("头墩 - tempDuns", tempDuns);
                            resultArr.push(tempDuns);
                        } else {
                            
                            if(zhongFilterArr.length && IsNoTouDun === false){
                                const diDunLen = 5 - diDunArr.length,
                                zhongDunLen = 5 - zhongDunArr.length;
                                zhongFilterArr.sort((a, b) => getCardValue(a.num) - getCardValue(b.num));

                                let diDunArrRes = fillWhile(diDunLen, diDunArr, [...zhongFilterArr]);
                                let zhongDunArrRes = fillWhile(zhongDunLen, zhongDunArr, [...zhongFilterArr]);
                                let touDunArrRes = fillWhile(3, [], [...zhongFilterArr]);
                                
                                tempDuns = [
                                    {
                                        paiType: "乌龙",
                                        paiArr: touDunArrRes,
                                    },{
                                        paiType: zhongDunLen == 5 ? "乌龙": zhongDunType,
                                        paiArr: zhongDunArrRes,
                                    },{
                                        paiType: diDunLen == 5 ? "乌龙": diDunType,
                                        paiArr: diDunArrRes,
                                    },
                                ];

                                if(IsPourWater(tempDuns)){
                                    console.log("倒水牌-过滤", tempDuns);
                                    continue
                                }

                                resultArr.push(tempDuns);
                                IsNoTouDun = true;
                            }
                        }

                        if(resultLen && typeof resultLen == "number" && resultArr.length == resultLen){
                            return resultArr;
                        }
                    }
                }
            }
        }
    }

    return resultArr;

    function fillWhile(num, targetArr, originArr){
        if(num <= 0) return targetArr;

        while(num > 0){
            const res = originArr.shift();
            targetArr.push(res);
            num -= 1;
        }

        return targetArr;
    }
}

// 查找同花顺( 同花 && 顺子)  有数据返回数组，没数据返回 false
export function findStraightFlushes(originCards) { 
    if(originCards.length < 5) {
        return;
    }

    // 按花色分组并查找同花顺  
    const suits = {},
        flowerCount = {};

    originCards.forEach(card => {  
        if (!suits[card.type]) {  
            suits[card.type] = [];  
            flowerCount[card.type] = 0;
        }  
        suits[card.type].push(card);
        flowerCount[card.type]++;
    });
    
    
    // 没有一种花色是大于 5 的，不构成同花顺
    if(!Object.values(flowerCount).some((count) => count >= 5)){
        return false;
    }

    let resultArr = [];
    for(let type in suits){
        const TypeArr = suits[type];
        const resArr = _findStraights(TypeArr)

        resArr && (resultArr = [ ...resultArr, ...resArr]);
    }

    if(resultArr.length > 0){
        const res = CompareSize(resultArr);
        const filterRes = filterArr(res);
    
        return ["同花顺", res, filterRes];
    }

    return false;
     
    function filterArr(targetArr){
        return originCards.filter((item) => {
            return !targetArr.some((card) => card.pidx === item.pidx);
        })
    }

    function CompareSize(arr){
        let maxArr = [],
            maxScore = null;

        arr.forEach((cards) => {
            const cardScore = cards.map((item) => getCardValue(item.num)).reduce((a, b) => a + b);

            if(maxScore === null || maxScore < cardScore){
                maxScore = cardScore
                maxArr = cards;
            }
        })

        return maxArr;
    }
}

// 查找所有同花顺( 同花 && 顺子)  有数据返回数组，没数据返回 false
export function findAllStraightFlushes(originCards) {
    if(originCards.length < 5) {
        return;
    }
    
    // 按花色分组并查找同花顺  
    const suits = {},
        flowerCount = {};

    originCards.forEach(card => {
        if (!suits[card.type]) {  
            suits[card.type] = [];  
            flowerCount[card.type] = 0;
        }  
        suits[card.type].push(card);
        flowerCount[card.type]++;
    });

    // 没有一种花色是大于 5 的，不构成同花顺
    if(!Object.values(flowerCount).some((count) => count >= 5)){
        return false;
    }

    let resultArr = [];
    for(let type in suits){
        const TypeArr = suits[type];
        const resArr = _findStraights(TypeArr)

        resArr && (resultArr = [ ...resultArr, ...resArr]);
    }

    if(resultArr.length > 0){
        return resultArr;
    }

    return false;

}

// 查找四条、铁支
export function findFourOfAKind(originCards) {  
    if(originCards.length < 5) {
        return;
    }

    // 用于统计每个数值出现的次数和对应的牌  
    const numCounts = {};  
    const fourCards = [];
    // 遍历所有牌，统计每个数值的出现次数和花色  
    originCards.forEach(card => {
        if (!numCounts[card.num]) {  
            numCounts[card.num] = { count: 0, types:[], cards: [] };  
        }  
        numCounts[card.num].count++;
        numCounts[card.num].cards.push(card);
        if (!numCounts[card.num].types.includes(card.type)) {  
            numCounts[card.num].types.push(card.type);  
        }

        if(numCounts[card.num].count >= 4 && numCounts[card.num].types.length >= 4){
            fourCards.push(numCounts[card.num].cards)
        }
    });
    

    if(fourCards.length <= 0){
        return false;
    } else {
        const res = CompareSize(fourCards);
        const filterRes = filterArr(res);

        return ["铁支", res, filterRes];
    }
    
    function filterArr(targetArr){
        return originCards.filter((item) => {
            return !targetArr.some((card) => card.pidx == item.pidx);
        })
    }
    
    function CompareSize(arr){
        let maxArr = null;

        arr.forEach((cards) => {
            if(maxArr === null || 
                (getCardValue(maxArr[0].num) < getCardValue(cards[0].num))
            ){
                maxArr = cards;
            }
        })

        return maxArr;
    }
}

// 查找所有四条、铁支
export function findAllFourOfAKind(originCards){
    if(originCards.length < 5) {
        return;
    }

    // 用于统计每个数值出现的次数和对应的牌  
    const numCounts = {};  
    const fourCards = [];
    // 遍历所有牌，统计每个数值的出现次数和花色  
    originCards.forEach(card => {
        if (!numCounts[card.num]) {  
            numCounts[card.num] = { count: 0, types:[], cards: [] };  
        }  
        numCounts[card.num].count++;
        numCounts[card.num].cards.push(card);
        if (!numCounts[card.num].types.includes(card.type)) {  
            numCounts[card.num].types.push(card.type);  
        }

        if(numCounts[card.num].count >= 4 && numCounts[card.num].types.length >= 4){
            fourCards.push(numCounts[card.num].cards)
        }
    });


    if(fourCards.length){
        return fourCards;
    }
    return false;
}

// 葫芦
export function findFullHouses(originCards) {
    if(originCards.length < 5) {
        return;
    }

    // 创建一个对象来存储每个数值的牌的数量  
    const numCounts = {};  
  
    // 遍历所有牌，统计每个数值的数量  
    originCards.forEach(card => {
        if (!numCounts[card.num]) {  
            numCounts[card.num] = { count: 0, cards: [] };  
        }  
        numCounts[card.num].count++;  
        numCounts[card.num].cards.push(card);  
    });  
  
    // 创建一个数组来存储所有找到的葫芦组合  
    const fullHouses = [];  
  
    // 遍历每个数值，检查是否可以组成葫芦  
    for (let num in numCounts) {
        if (numCounts[num].count >= 3) { // 必须至少有三张相同的牌  
            // 检查是否有另一对不同的数值且数量至少为2  
            for (let otherNum in numCounts) {
                if (otherNum !== num && numCounts[otherNum].count >= 2) {  
                    // 构造葫芦组合（这里只记录数值，如果需要具体牌面可以修改）
                    const triplet = numCounts[num].cards.slice(0, 3), // 三张
                     pair = numCounts[otherNum].cards.slice(0, 2); // 一对

                    // 添加到结果数组中（注意：这里可能包含重复的组合，如果需要去重可以进一步处理）  
                    fullHouses.push([...triplet, ...pair]);  
                }  
            }  
        }  
    }  
  
    // 注意：上面的实现会返回所有可能的葫芦组合，包括重复的（如果有多个相同的三张和一对）  
    // 如果需要更精确的结果（例如，只包含实际牌面的组合），则需要进一步处理  
  
    // 这里我们返回的是一个包含数值的葫芦组合，如果需要具体牌面，可以进一步构造  
    // 但由于葫芦只关心数值而不关心花色，所以上面的实现已经足够 
    

    if(fullHouses.length > 0){
        const res = CompareSize(fullHouses),
        filterRes = filterArr(res);

        return ["葫芦", res, filterRes];
    }
    return false;
    
    function CompareSize(arr){
        let maxArr = null, 
            maxScore = 0;

        arr.forEach((cards) => {
            const score = cards.map((card) => getCardValue(card.num)).reduce((a, b) => a + b);
            if(maxArr === null || maxScore < score){
                maxArr = cards;
                maxScore = score
            }
        })

        return maxArr;
    }
    
    function filterArr(targetArr){
        const res = originCards.filter((item) => {
            return !targetArr.some((card) => card.pidx == item.pidx);
        })
        return res;
    }
}  

//所有葫芦
export function findAllFullHouses(originCards){
    if(originCards.length < 5) {
        return;
    }

    // 创建一个对象来存储每个数值的牌的数量  
    const numCounts = {};  
  
    // 遍历所有牌，统计每个数值的数量  
    originCards.forEach(card => {
        if (!numCounts[card.num]) {  
            numCounts[card.num] = { count: 0, cards: [] };  
        }  
        numCounts[card.num].count++;  
        numCounts[card.num].cards.push(card);  
    });  
  
    // 创建一个数组来存储所有找到的葫芦组合  
    const fullHouses = [];  
  
    // 遍历每个数值，检查是否可以组成葫芦  
    for (let num in numCounts) {
        if (numCounts[num].count >= 3) { // 必须至少有三张相同的牌  
            // 检查是否有另一对不同的数值且数量至少为2  
            for (let otherNum in numCounts) {
                if (otherNum !== num && numCounts[otherNum].count >= 2) {  
                    // 构造葫芦组合（这里只记录数值，如果需要具体牌面可以修改）
                    const triplet = numCounts[num].cards.slice(0, 3), // 三张
                     pair = numCounts[otherNum].cards.slice(0, 2); // 一对

                    // 添加到结果数组中（注意：这里可能包含重复的组合，如果需要去重可以进一步处理）  
                    fullHouses.push([...triplet, ...pair]);  
                }  
            }  
        }  
    }  
  
    // 注意：上面的实现会返回所有可能的葫芦组合，包括重复的（如果有多个相同的三张和一对）  
    // 如果需要更精确的结果（例如，只包含实际牌面的组合），则需要进一步处理  
  
    // 这里我们返回的是一个包含数值的葫芦组合，如果需要具体牌面，可以进一步构造  
    // 但由于葫芦只关心数值而不关心花色，所以上面的实现已经足够 
    
    if(fullHouses.length > 0){
        return fullHouses;
    }
    return false;
}

// 查找同花（5张同花色牌）  有数据返回数组，没数据返回 false
export function findSameFlower(originCards) {
    if(originCards.length < 5) {
        return;
    }

    // 定义一个对象来计数每种花色的牌数
    const flowerCards = {
        hongtao: [],
        heitao: [],
        fangkuai: [],
        meihua: [],
    }

    const checkFlowerCards = {};
    // 遍历牌组，计算每种花色的数量
    originCards.forEach(card => {
        flowerCards[card.type].push(card);
        
        if(flowerCards[card.type].length >= 5) {
            checkFlowerCards[card.type] = flowerCards[card.type];
        }
    });  

    const cardsKeyArr = Object.keys(checkFlowerCards);
    const len = cardsKeyArr.length;
    if(len == 1){
        const res = checkFlowerCards[cardsKeyArr[0]].sort((a, b) => { // 从大到小排序
            return getCardValue(b.num) - getCardValue(a.num);
        }).slice(0, 5),
        filterRes = filterArr(res);

        return ["同花", res, filterRes];
    } else if(len > 1){
        // 多个同花
        const res = CompareSize(checkFlowerCards),
            filterRes = filterArr(res);
        return ["同花", res, filterRes ]
    }

    return false; // 没有任何一种花色可以组成同花  

    function filterArr(targetArr){
        return originCards.filter((item) => {
            return !targetArr.some((card) => card.pidx == item.pidx);
        })
    }

    function CompareSize(obj){
        let maxArr = null;

        for(let type in obj){
            const cards = obj[type].sort((a, b) => { // 从大到小排序
                return getCardValue(b.num) - getCardValue(a.num);
            }).slice(0, 5);

            if(maxArr === null) {
                maxArr = cards;
            } else {
                for(let i = 0; i < maxArr.length; i ++){
                    const maxItem = getCardValue(maxArr[i].num),
                        cardItem = getCardValue(cards[i].num);

                    if(maxItem > cardItem || maxItem == cardItem){
                        continue;
                    } else if(maxItem < cardItem){
                        maxArr = cards;
                        break;
                    }
                }
            }
        }

        return maxArr;
    }
}

// 查找所有同花（5张同花色牌）  有数据返回数组，没数据返回 false
export function findAllSameFlower(originCards){
    
    if(originCards.length < 5) {
        return;
    }

    // 定义一个对象来计数每种花色的牌数
    const flowerCards = {
        hongtao: [],
        heitao: [],
        fangkuai: [],
        meihua: [],
    }

    const checkFlowerCards = {};
    // 遍历牌组，计算每种花色的数量
    originCards.forEach(card => {
        flowerCards[card.type].push(card);
        
        if(flowerCards[card.type].length >= 5) {
            checkFlowerCards[card.type] = flowerCards[card.type];
        }
    });

    const len = Object.keys(checkFlowerCards).length;
    let resultFlowerCards = [];
    if(len > 0){
        for(let key in checkFlowerCards){
            const item = checkFlowerCards[key];
            const enumRes = enumCall(item);
            resultFlowerCards = [ ...resultFlowerCards, ...enumRes ];
        }

        return resultFlowerCards;
    }

    return false;



    function enumCall(array){
        let len = array.length - 5;
        if(len < 0) return array;

        const result = [];
        for(let i = 0; i <= len; i ++){
            result.push(array.slice(i, i + 5));
        }

        return result;
    }
}

// 查找顺子
export function findStraight(originCards) {
    // 小于 5 不构成顺子
    if(Array.isArray(originCards) && originCards.length < 5){
        return false;
    }

    const AllStraights = _findStraights(originCards);
    
    if(AllStraights.length > 0){
        const res = CompareSize(AllStraights);
        const filterRes = filterArr(res);
    
        return ["顺子", res, filterRes];
    }
    return false;
     
    function filterArr(targetArr){
        return originCards.filter((item) => {
            return !targetArr.some((card) => card.pidx === item.pidx);
        })
    }

    function CompareSize(arr){
        let maxArr = [],
            maxScore = null;

        arr.forEach((cards) => {
            const cardScore = cards.map((item) => getCardValue(item.num)).reduce((a, b) => a + b);

            if(maxScore === null || maxScore < cardScore){
                maxScore = cardScore
                maxArr = cards;
            }
        })

        return maxArr;
    }
}

// 查找所有顺子
export function findAllStraight(originCards){
    // 小于 5 不构成顺子
    if(Array.isArray(originCards) && originCards.length < 5){
        return false;
    }

    const AllStraights = _findStraights(originCards);
    
    if(AllStraights.length > 0){
        return AllStraights;
    }
    return false;
}

// 查找三条
export function findMaxTriplet(originCards) {
    // 创建一个对象来统计每个点数的出现次数及对应的牌  
    let counts = {};  
    
    // 遍历每张牌，统计点数及收集牌  
    for (let card of originCards) {
        const num = card.num;  
        if (counts[num]) {  
            counts[num].count++;  
            counts[num].cards.push(card);  
        } else {  
            counts[num] = { count: 1, cards: [card] };  
        }  
    }

    let maxTriplet = null; // 用于存储最大的三条牌型组合  
    let maxPoints = -Infinity; // 存储最大的点数（用于比较，实际上是点数的和）

    // 遍历统计结果，查找三条  
    for (let num in counts) {  
        if (counts[num].count >= 3) { // 如果某个点数出现了至少3次  
            const triplet = counts[num].cards.slice(0, 3); // 取前三张牌作为三条  
            const score = getCardValue(triplet[0].num);

            if (!maxTriplet || score > maxPoints) {  
                // 如果没有找到过三条，或者当前三条的点数比已知的最大三条大  
                maxTriplet = triplet;
                maxPoints = score;
            }  
        }
    }

    if(maxTriplet){
        const filterRes = filterArr(maxTriplet);
        return ["三条", maxTriplet, filterRes];
    }
    return false;

    function filterArr(targetArr){
        return originCards.filter((item) => {
            return !targetArr.some((card) => card.pidx === item.pidx);
        })
    }
}

// 查找所有三条
export function findAllMaxTriplet(originCards){
    // 创建一个对象来统计每个点数的出现次数及对应的牌  
    let counts = {};  
    const tripletArr = {};
        
    // 遍历每张牌，统计点数及收集牌  
    for (let card of originCards) {
        const num = card.num;  
        if (counts[num]) {  
            counts[num].count++;  
            counts[num].cards.push(card);  
        } else {  
            counts[num] = { count: 1, cards: [card] };  
        }

        if (counts[num].count >= 3) { // 如果某个点数出现了至少3次  
            tripletArr[num] = counts[num]; // 取前三张牌作为三条  
        }
    }

    const len = Object.keys(tripletArr).length;
    let resultTripletArr = [];
    if(len > 0){
        for(let key in tripletArr){
            const item = tripletArr[key];
            const enumRes = enumCall(item.cards);
            resultTripletArr = [ ...resultTripletArr, ...enumRes ];
        }

        return resultTripletArr;
    }

    return false;

    function enumCall(array){
        let len = array.length - 3;
        if(len < 0) return array;

        const result = [];
        for(let i = 0; i <= len; i ++){
            result.push(array.slice(i, i + 3));
        }
        
        return result;
    }
    
}

// 查找两对
export function findMaxTwoPairs(originCards) {
    // 创建一个对象来统计每个点数的出现次数及对应的牌  
    let counts = {};  
    
    // 遍历每张牌，统计点数及收集牌  
    for (let card of originCards) {  
        const num = card.num;  
        if (counts[num]) {  
            counts[num].count++;  
            counts[num].cards.push(card);  
        } else {  
            counts[num] = { count: 1, cards: [card] };  
        }  
    }

    let maxTwoPairs = null; // 存储最大的两对牌型  
    let maxPoints = -Infinity; // 存储最大的点数（用于比较，实际上是点数的和）

    // 遍历统计结果，查找两对  
    for (let num1 in counts) {
        if (counts[num1].count < 2) continue; // 如果点数不足两张，跳过  

        // 移除当前点数后，检查其他点数是否能形成另一对  
        for (let num2 in counts) {
            if (counts[num2].count < 2 || num1 === num2) continue; // 跳过相同的点数或点数不足两张的情况  

            const item1 = counts[num1],
                item2 = counts[num2];

            const score1 = getCardValue(item1.cards[0].num),
                score2 = getCardValue(item2.cards[0].num);
                
            // 计算当前两对的点数和（这里简化为点数相加，可以根据需要调整比较逻辑）  
            let pointsSum = score1 + score2; // 假设num是字符串形式的点数  

            // 检查是否找到了更大的两对  
            if (pointsSum > maxPoints) {  
                maxPoints = pointsSum;  
                // 构造两对牌型的组合（只取前两张形成每对的牌）  
                maxTwoPairs = [  
                   ...item1.cards.slice(0, 2), ...item2.cards.slice(0, 2)
                ];
            }  
        }
    }

    if(maxTwoPairs){
        const filterRes = filterArr(maxTwoPairs);
        return ["两对", maxTwoPairs, filterRes];
    }
    return false;


    function filterArr(targetArr){
        return originCards.filter((item) => {
            return !targetArr.some((card) => card.pidx === item.pidx);
        })
    }
}

// 查找所有两对
export function findAllMaxTwoPairs(originCards){
    // 创建一个对象来统计每个点数的出现次数及对应的牌  
    let counts = {};  
        
    // 遍历每张牌，统计点数及收集牌  
    for (let card of originCards) {
        const num = card.num;  
        if (counts[num]) {  
            counts[num].count++;  
            counts[num].cards.push(card);  
        } else {  
            counts[num] = { count: 1, cards: [card] };  
        }

    }

    let TwoPairsArr = []; 
    // 遍历统计结果，查找两对  
    for (let num1 in counts) {
        if (counts[num1].count < 2) continue; // 如果点数不足两张，跳过  

        // 移除当前点数后，检查其他点数是否能形成另一对  
        for (let num2 in counts) {
            if (counts[num2].count < 2 || num1 === num2) continue; // 跳过相同的点数或点数不足两张的情况  

            const item1 = counts[num1],
                item2 = counts[num2];

            TwoPairsArr.push([  
                ...item1.cards.slice(0, 2), ...item2.cards.slice(0, 2)
            ])
        }
    }


    if(TwoPairsArr.length){
        return TwoPairsArr.length > 1 ? removeDuplicateSubarrays(TwoPairsArr) : TwoPairsArr
    }
    
    return false;

}

// 查找一对
export function findMaxOnePairs(originCards) {
    // 创建一个对象来统计每个点数的出现次数及对应的牌  
    let counts = {};  
    
    // 遍历每张牌，统计点数及收集牌  
    for (let card of originCards) {  
        const num = card.num;  
        
        if (counts[num]) {  
            counts[num].count++;  
            counts[num].cards = [ ...counts[num].cards, card];  
        } else {  
            counts[num] = { count: 1, cards: [card] };  
        }
    }

    
    let maxOnePairs = null; // 存储最大的一对牌型  
    let maxPoints = -Infinity; // 存储最大的点数（用于比较，实际上是点数的和）

    // 移除当前点数后，检查其他点数是否能形成另一对  
    for (let key in counts) {
        if (counts[key].count < 2) continue; // 跳过相同的点数或点数不足两张的情况  

        const item = counts[key];
        const score = getCardValue(item.cards[0].num);

        // 检查是否找到了更大的两对  
        if (score > maxPoints) {  
            maxPoints = score;  
            // 构造两对牌型的组合（只取前两张形成每对的牌）  
            // maxOnePairs = item.cards.slice(0, 2);
            maxOnePairs = item.cards;
        }  
    }
    
    counts = {};
    if(maxOnePairs){
        const filterRes = filterArrOnePairs(maxOnePairs);
        return ["一对", maxOnePairs, filterRes];
    }
    return false;

    function filterArrOnePairs(targetArr){
        return originCards.filter((item) => {
            return ![...targetArr].some((card) => card.pidx === item.pidx);
        })
    }
}

// 查找所有一对
export function findAllMaxOnePairs(originCards){
    // 创建一个对象来统计每个点数的出现次数及对应的牌  
    let counts = {};  
    
    // 遍历每张牌，统计点数及收集牌  
    for (let card of originCards) {  
        const num = card.num;  
        if (counts[num]) {  
            counts[num].count++;  
            counts[num].cards.push(card);  
        } else {  
            counts[num] = { count: 1, cards: [card] };  
        }  
    }
    
    const OnePairsArr = []; // 存储最大的一对牌型  
    // 移除当前点数后，检查其他点数是否能形成另一对  
    for (let key in counts) {
        if (counts[key].count < 2) continue; // 跳过相同的点数或点数不足两张的情况  
        const item = counts[key];
        OnePairsArr.push(item.cards.slice(0, 2));
    }

    if(OnePairsArr){
        return OnePairsArr.length > 1 ? removeDuplicateSubarrays(OnePairsArr) : OnePairsArr
    }

    return false;
}

// 牌的点数
export function getCardValue(num){
    const number = Number(num);

    if(!isNaN(number) && typeof number === "number" && number >= 2 && number <= 10){
        return number;
    }

    switch(num){
        case 'A': return 14;  
        case 'K': return 13;  
        case 'Q': return 12;  
        case 'J': return 11; 
        default: throw new Error('Invalid card number');  
    }
}

// 辅助函数：检查 5张牌 是否为顺子  
function _isStraight(cards) {
    if(cards.length < 5) {
        return false;
    }

    const nums = cards.map(card => card?.num);
    const Aidx = nums.indexOf("A");

    if(Aidx !== -1){
        nums.splice(Aidx, 1);
    }

    nums.sort((a, b) => getCardValue(a) - getCardValue(b));

    const cardOrder = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'],
        minCardOrder = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];

    if(Aidx === -1){
        return checkStraight(nums, cardOrder);
    } else {
        return checkStraight([...nums, "A"], cardOrder) || checkStraight(["A", ...nums], minCardOrder);
    }

    function checkStraight(arr, Order){
        for (let i = 0; i < arr.length - 1; i++) {
            if (arr[i + 1] !== nextCard(arr[i], Order)) {  
                return false;  
            }
        } 
        return true;
    }

    // 辅助函数：获取下一张牌的数值  
    function nextCard(num, Order) {  
        const index = Order.indexOf(num);  
        return Order[index + 1] || '';  
    }  
}

// 辅助函数：检查是否有顺子
function _isHasStraights(cards){
    if(cards.length < 5) { return false; }

    let used = new Array(cards.length).fill(false); // 标记数组，表示每张牌是否已被使用
    let results = [];

    cards.sort((a, b) => getCardValue(a.num) - getCardValue(b.num));

    const cardOrder = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'],
        minCardOrder = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];

    backtrack(0, [], used);

    return results.length > 0 ? true : false;

    function backtrack(start, path, used) {
        if(results.length > 0) { return };
        
        if (path.length === 5) {

            // 检查是否构成顺子  
            if (_isStraight(path)) {
                results.push(path.map(card => ({ ...card }))); // 深拷贝以避免修改原数组  
            } 
            
            return;  
        }

        for (let i = start; i < cards.length; i++) { 
            if (used[i]) continue; // 如果已经使用过，跳过

            const curCard = cards[i];
            // 校验 path 不是以 A 为开头。用默认 order
            // 这里校验 path为空，并且要求 curCard 不能为 A 是通的。
            // 因为上面做了排序，cards 牌组中的 A 都会放在末尾
            if(curCard?.num !== "A" && 
                ( path.length === 0 ||
                checkStraight(path[path.length - 1], curCard, path[0].num === "A" ? true : false))
            ) {
                path.push(curCard);
                used[i] = true;
                backtrack(i + 1, path, used);
                path.pop();  
                used[i] = false;  
            }

            // 如果当前是 A，并且 path.length === 0，那就要求 牌型组合判断 要从 0 开始匹配。 因为 A 可以是 1 的数值。
            if(curCard?.num === "A" && path.length === 0){
                path.push(curCard);
                used[i] = true;
                backtrack(0, path, used);
                path.pop();  
                used[i] = false;
            }

            // 如果当前是 A
            // 条件判断1：path.length == 4，要求其 A 必须是要放在末尾的值。否则构不成顺子
            if(curCard?.num === "A" && path.length === 4 && checkStraight(path[path.length - 1], curCard, path[0].num === "A" ? true : false)) {
                path.push(curCard);
                used[i] = true;
                backtrack(i + 1, path, used);
                path.pop();  
                used[i] = false; 
            }
        }
    }
    
    // 辅助函数：获取下一张牌的数值  
    function nextCard(num, Order) {  
        const index = Order.indexOf(num);
        return Order[index + 1] || '';  
    }

    // 辅助函数：校验两个值是否连续
    function checkStraight(prevCard, curCard, isFirstA){
        const prevNum = prevCard.num,
            curNum = curCard.num;

        const targetNum = nextCard(prevNum, isFirstA ? minCardOrder : cardOrder);
        
        if(curNum == targetNum){
            return true;
        }
        return false;
    }

}

// 辅助函数：查找顺子
function _findStraights(cards) {
    if(cards.length < 5) { return []; }

    let used = new Array(cards.length).fill(false); // 标记数组，表示每张牌是否已被使用
    let results = [];

    cards.sort((a, b) => getCardValue(a.num) - getCardValue(b.num));

    const cardOrder = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'],
        minCardOrder = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];

    backtrack(0, [], used);

    function backtrack(start, path, used) {

        if (path.length === 5) {

            // 检查是否构成顺子  
            if (_isStraight(path)) {
                results.push(path.map(card => ({ ...card }))); // 深拷贝以避免修改原数组  
            } 
            
            return;  
        }

        for (let i = start; i < cards.length; i++) { 
            if (used[i]) continue; // 如果已经使用过，跳过

            const curCard = cards[i];
            // 校验 path 不是以 A 为开头。用默认 order
            // 这里校验 path为空，并且要求 curCard 不能为 A 是通的。
            // 因为上面做了排序，cards 牌组中的 A 都会放在末尾
            if(curCard?.num !== "A" && 
                ( path.length === 0 ||
                checkStraight(path[path.length - 1], curCard, path[0].num === "A" ? true : false))
            ) {
                path.push(curCard);
                used[i] = true;
                backtrack(i + 1, path, used);
                path.pop();  
                used[i] = false;  
            }

            // 如果当前是 A，并且 path.length === 0，那就要求 牌型组合判断 要从 0 开始匹配。 因为 A 可以是 1 的数值。
            if(curCard?.num === "A" && path.length === 0){
                path.push(curCard);
                used[i] = true;
                backtrack(0, path, used);
                path.pop();  
                used[i] = false;
            }

            // 如果当前是 A
            // 条件判断1：path.length == 4，要求其 A 必须是要放在末尾的值。否则构不成顺子
            if(curCard?.num === "A" && path.length === 4 && checkStraight(path[path.length - 1], curCard, path[0].num === "A" ? true : false)) {
                path.push(curCard);
                used[i] = true;
                backtrack(i + 1, path, used);
                path.pop();  
                used[i] = false; 
            }
        }
    }
    
    // 辅助函数：获取下一张牌的数值  
    function nextCard(num, Order) {  
        const index = Order.indexOf(num);
        return Order[index + 1] || '';  
    }

    // 辅助函数：校验两个值是否连续
    function checkStraight(prevCard, curCard, isFirstA){
        const prevNum = prevCard.num,
            curNum = curCard.num;

        const targetNum = nextCard(prevNum, isFirstA ? minCardOrder : cardOrder);
        
        if(curNum == targetNum){
            return true;
        }
        return false;
    }

    return results;
}

// 辅助函数：去重
function removeDuplicateSubarrays(arrays) {  
    // 辅助函数，用于将子数组转换为一个由 pidx 组成的排序数组  
    function toPidxArray(subarray) {  
        return subarray.map(obj => obj.pidx).sort();  
    }  
  
    // 使用 Set 来跟踪已经遇到过的 pidx 数组  
    const seen = new Set();  
    // 用于存储去重后的结果  
    const result = [];  
  
    for (const array of arrays) {  
        // 将当前子数组转换为 pidx 数组  
        const pidxArray = toPidxArray(array);  
        // 将 pidx 数组转换为字符串，以便存储在 Set 中  
        const pidxArrayStr = pidxArray.join(',');  
  
        // 如果这个 pidx 数组是新的（即之前没遇到过），则添加到结果中  
        if (!seen.has(pidxArrayStr)) {  
            seen.add(pidxArrayStr);  
            result.push(array); // 或者如果你想要保持排序后的 pidx 数组，可以 push(toPidxArray(array).map(pidx => ({ pidx, ... }))，但这会丢失原始对象的其他属性  
        }  
        // 注意：这里我们没有修改原始数组，而是创建了一个新的结果数组  
    }
  
    return result;  
}

// 辅助函数：检查是否为同花顺
function isFlushAndStraights(cards){
    if(cards.length < 5) {
        return false;
    }

    // 定义一个对象来计数每种花色的牌数
    const flowerCards = {
        hongtao: [],
        heitao: [],
        fangkuai: [],
        meihua: [],
    }

    const checkFlowerCards = {};
    // 遍历牌组，计算每种花色的数量
    cards.forEach(card => {
        flowerCards[card.type].push(card);
        if(flowerCards[card.type].length >= 5) {
            checkFlowerCards[card.type] = flowerCards[card.type];
        }
    });

    const cardsKeyArr = Object.keys(checkFlowerCards);
    const len = cardsKeyArr.length;

    if(len <= 0){
        return false;
    }

    for(let key in checkFlowerCards){
        const item = checkFlowerCards[key];
        return _isStraight(item)
    }
    
    return false;
}

// 辅助函数：检查是否为5张同花，或者有同花
function isFlush(cards) {

    if(cards.length < 5) {
        return false;
    }

    // 定义一个对象来计数每种花色的牌数
    const flowerCards = {
        hongtao: [],
        heitao: [],
        fangkuai: [],
        meihua: [],
    }

    const checkFlowerCards = {};
    // 遍历牌组，计算每种花色的数量
    cards.forEach(card => {
        flowerCards[card.type].push(card);
        
        if(flowerCards[card.type].length >= 5) {
            checkFlowerCards[card.type] = flowerCards[card.type];
        }
    });  
    
    const cardsKeyArr = Object.keys(checkFlowerCards);
    const len = cardsKeyArr.length;

    return len > 0 ? true : false;
}  

// 辅助函数：检查是否有四张相同的牌  
function hasFourOfAKind(cards) {
    if(cards.length < 4){
        return false;
    }

    const cardCounts = {};
    const fourCards = [];
    cards.forEach(card => {
        if(!cardCounts[card?.num]){
            cardCounts[card?.num] = { count: 0, types: [] }
        }

        cardCounts[card?.num].count++;
        if (!cardCounts[card?.num].types.includes(card?.type)) {  
            cardCounts[card?.num].types.push(card?.type);  
        }

        
        if(cardCounts[card?.num].count >= 4 && cardCounts[card?.num].types.length >= 4){
            fourCards.push(cardCounts[card?.num].cards)
        }
    });

    return fourCards.length > 0 ? true : false;  
}  

// 辅助函数：检查是否有三张相同的牌和一对  
export function hasThreeOfAKindAndPair(cards) {
    if(cards.length < 5){
        return false;
    }

    const cardCounts = {};  
    cards.forEach(card => {  
        cardCounts[card?.num] = (cardCounts[card?.num] || 0) + 1;  
    });

    const counts = Object.values(cardCounts);  
    return counts.some(count => count >= 3) && counts.some(count => count == 2);  
}  

// 辅助函数：检查是否有三张相同的牌  
function hasThreeOfAKind(cards) {
    if(cards.length < 3){
        return false;
    }

    const cardCounts = {};  
    cards.forEach(card => {  
        cardCounts[card?.num] = (cardCounts[card?.num] || 0) + 1;  
    });  
    return Object.values(cardCounts).some(count => count >= 3);  
}  

// 辅助函数：检查是否有两对  
function hasTwoPairs(cards) {  
    if(cards.length < 4){
        return false;
    }
    const cardCounts = {};  
    cards.forEach(card => {  
        cardCounts[card?.num] = (cardCounts[card?.num] || 0) + 1;  
    });  
    
    const counts = Object.values(cardCounts);  

    return counts.filter(count => count >= 2).length >= 2;  
}  

// 辅助函数：检查是否有一对  
function hasPair(cards) {
    if(cards.length < 2){
        return false;
    }

    const cardCounts = {};  
    cards.forEach(card => {  
        cardCounts[card?.num] = (cardCounts[card?.num] || 0) + 1;  
    });  
    return Object.values(cardCounts).some(count => count >= 2);  
}