const SocketPlayerStatus = {
    // 0=未准备 1=已准备 2=发牌中 3=理牌 4=确定出牌 5=结果展示
}

/**
 * const (
    CardTypeHeart   = 1 // 红桃
    CardTypeDiamond = 2 // 方块
    CardTypeSpade   = 3 // 黑桃
    CardTypeClub    = 4 // 梅花
)
 *   
http://sss8-7gdapipq74fb40ce-1327987324.tcloudbaseapp.com/?kid=636309
 */



export const SuperCardType = {
    15: "一条龙", // 超级一条龙  (13张牌)
    16: "六对半", // 超级牌  (六对半)
    17: "三顺子", // 超级牌  (三顺子)
    18: "三同花", // 超级牌  (三同花)
}

export const SuperTypeImg = {
    15: [ `type-yi.png`, `type-tiao.png`, `type-long.png` ],
    16: [ `type-liu.png`, `type-dui.png`, `type-ban.png` ],
    17: [ `type-san.png`, `type-shun.png`, `type-zi.png` ],
    18: [ `type-san.png`, `type-tong.png`, `type-hua.png` ]
}

export const SeverCardType = {
    1: "hongtao",
    2: "fangkuai",
    3: "heitao",
    4: "meihua"
}

// 房间类型:play_type 1=经典 2=通比 3=纯一色 4=庄闲玩法
export const RoomPlayType = {
    0: "没有",
    1: "经典",
    2: "通比",
    3: "纯一色",
    4: "庄闲玩法"
}

export const RoomCondition = {
    0: "",
    1: "首局人满开始",
    2: "每局人满开始"
}

export const PkpTypeImg = {
    "同花顺": "pkp-tonghuashun.png",
    "铁支": "pkp-tiezhi.png",
    "葫芦": "pkp-hulu.png",
    "同花": "pkp-tonghua.png",
    "顺子": "pkp-shunzi.png",
    "三条": "pkp-santiao.png",
    "两对": "pkp-liangdui.png",
    "一对": "pkp-duizi.png",
    "乌龙": "pkp-wulong.png"
}